import React from 'react';

import Layout from '../components/Layout';
// import Scroll from '../components/Scroll';

// import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';

const IndexPage = () => (
  <Layout>
    {/* eslint-disable jsx-a11y/media-has-caption */}

    <section className="brand-videobg">
      <video autoPlay={true} playsInline={true} muted={true} loop={true}>
        <source
          src="https://static.jukinmedia.com/jm/jm-brands-hp-720.webm"
          type="video/mp4"
        />
      </video>
      <div className="brand-copy home">
        <h1>WE ARE JUKIN BRANDS</h1>
        <p>Global digital-native brands, with a massive US footprint</p>
      </div>
    </section>
    <SocialLinks siteLogoClass="hide" />
    <Footer />
  </Layout>
);

export default IndexPage;
